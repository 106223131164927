<template>
  <div style="height: auto">
    <Banner :postData="groupPost" />

    <div class="lg:py-16 md:py-10 py-4 lg:px-24 md:px-24 px-5">
      <div class="lg:flex md:flex">
        <div class="lg:w-2/3 md:w-2-3 lg:pr-6 md:pr-6 pr-0">
          <!-- <div class="flex items-center gap-4 my-8">
            <div class="circle"></div>
            <div class="">
              <h4 class="header">Kotal Treasury</h4>
              <span class="text-gray font-medium">a few second ago</span>
            </div>
          </div> -->
          <PollView
            v-if="groupPost.PollQuestionId"
            :votedIds="groupPost.VotedIds"
            :PostId="groupPost.post_group_mapping_post_id"
            :PollId="groupPost.PollQuestionId"
            :Ids="groupPost.AnswersIds"
            :Question="groupPost.PollQuestion"
            :Answers="groupPost.PollAnswers"
            :AnswersVotes="groupPost.AnswersVotes"
          />
          <EcoLeft v-else :postData="groupPost" />
          <!-- <post-comment :post="groupPost" /> -->
          <div class="lg:flex mb-4 items-center" @click="openLoadingContained">
            <div class="flex justify-between">
              <div class="flex items-center">
                <div class="post-likeheart">
                  <!-- <main>
                      <input class="l" type="checkbox" />
                    </main>-->
                  <feather-icon
                    ref="loadableButton"
                    :id="'like-' + groupPost.post_UniqueId"
                    class="mr-2 groups_like_icon"
                    icon="HeartIcon"
                    :svgClasses="{
                      'text-danger fill-current stroke-current':
                        groupPost.is_active,
                    }"
                    @click.stop="
                      likePost(
                        groupPost.is_active,
                        groupPost.postId,
                        groupPost.likeId,
                        groupPost.post_user_id,
                        groupPost.post_UniqueId,
                        groupPost.usersDetails_UniqueId,
                        'like-' + groupPost.post_UniqueId
                      )
                    "
                  ></feather-icon>
                  <span
                    class="likes-fonts text-center"
                    v-if="
                      groupPost.LIKECOUNT == null || groupPost.LIKECOUNT == 0
                    "
                    >Like</span
                  >
                  <span
                    class="likes-fonts post-liketick"
                    v-else-if="groupPost.LIKECOUNT == 1"
                    >{{ groupPost.LIKECOUNT }} Like</span
                  >
                  <span class="likes-fonts" v-else
                    >{{ groupPost.LIKECOUNT }} Likes</span
                  >
                </div>
                <!-- <div class="flex items-center">
                <feather-icon
                  class="mr-3 groups_like_icon"
                  icon="HeartIcon"
                  ref="loadableButton"
                  id="button-with-loading"
                  :svgClasses="{
                    'text-danger fill-current stroke-current':
                      groupPost.is_active,
                  }"
                  @click="
                    likePost(
                      groupPost.is_active,
                      groupPost.postId,
                      groupPost.likeId,
                      groupPost.post_user_id,
                      groupPost.post_UniqueId,
                      groupPost.usersDetails_UniqueId
                    )
                  "
                ></feather-icon>
                <div>
                  <span
                   v-if="
                      groupPost.LIKECOUNT == null || groupPost.LIKECOUNT == 0
                    "
                  >Like</span>
                  <span v-else-if="groupPost.LIKECOUNT == 1">{{ groupPost.LIKECOUNT }} Like</span>
                  <span v-else>{{ groupPost.LIKECOUNT }} Likes</span>
                </div>
              </div> -->
              </div>
              <div class="flex items-center">
                <div class="flex items-center">
                  <feather-icon
                    class="lg:ml-8 md:ml-6 mr-3 groups_like_icon"
                    icon="MessageSquareIcon"
                    ref="loadableButton"
                    id="button-with-loading"
                  ></feather-icon>
                  <div
                    v-if="
                      groupPost.comment_count == null ||
                      groupPost.comment_count == 0
                    "
                  >
                    <span>No Comments</span>
                  </div>
                  <div v-else-if="groupPost.comment_count == 1">
                    <span>{{ groupPost.comment_count }} Comment</span>
                  </div>
                  <div v-else>
                    <span>{{ groupPost.comment_count }} Comments</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="onboard-socialicon my-4 flex">
              <social-sharing
                :url="copyText"
                inline-template
                @open="socialShareOpen"
              >
                <div class="flex items-center">
                  <feather-icon
                    class="lg:ml-8 md:ml-6 mr-3 groups_like_icon"
                    icon="Share2Icon"
                    ref="loadableButton"
                    id="button-with-loading"
                  ></feather-icon>

                  <div class="flex gap-2 items-center">
                    <network network="whatsapp">
                      <img
                        src="@/Core/assets/images/pages/carousel/whatsapp.png"
                        class="icon-size"
                        @open="addSharedPromotionLog('whatsapp')"
                      />
                    </network>
                    <network network="facebook">
                      <img
                        src="@/Core/assets/images/pages/carousel/facebook.png"
                        class="icon-size"
                        @click="addSharedPromotionLog('facebook')"
                      />
                    </network>
                    <network network="email">
                      <img
                        src="@/Core/assets/images/pages/carousel/new-email-button.png"
                        class="img-responsive social-icon-img icon-size"
                      />
                    </network>
                    <network network="linkedin">
                      <img
                        src="@/Core/assets/images/pages/carousel/linkedin.png"
                        class="icon-size"
                        @click="addSharedPromotionLog('linkedin')"
                      />
                    </network>
                    <network network="twitter">
                      <img
                        src="@/Core/assets/images/pages/carousel/twitter.png"
                        class="icon-size"
                        @click="addSharedPromotionLog('twitter')"
                      />
                    </network>
                  </div>
                </div>
              </social-sharing>
            </div>
          </div>
          <div
            class="smp_comments_container smp_mt_4 mt-4"
            v-show="
              groupPost.first_comment ||
              (groupPost.comments && groupPost.comments.length > 0)
            "
          >
            <ul class="smp_user-comments-list">
              <li
                class="smp_commented_user items-center"
                v-if="groupPost.first_comment"
              >
                <div class="parent-userDplabl">
                  <div class="mr-3 pt-2">
                    <vs-avatar
                      v-if="groupPost.first_comment_user_image != null"
                      class="m-0"
                      :src="magittUrl + groupPost.first_comment_user_image"
                      size="30px"
                      :text="groupPost.first_comment_user_name"
                    />
                    <vs-avatar
                      v-if="groupPost.first_comment_user_image == null"
                      class="bg-primary"
                      size="30px"
                      :text="groupPost.first_comment_user_name"
                    />
                  </div>
                  <div class="smp_leading_tight smp_textsocial">
                    <p
                      class="font-medium h4-class smp_cursor-pointer cust-pt-pb cust-pb-5"
                    >
                      {{ groupPost.first_comment_user_name }}
                    </p>
                    <span class="post-comm-spn">{{
                      groupPost.first_comment
                    }}</span>
                  </div>
                </div>
                <ul class="smp_user-comments-list" v-if="showReplies">
                  <li
                    class="smp_commented_user items-center ml-2 smp_text_pddd"
                    v-for="comment of groupPost.first_comment_replies"
                    :key="comment.comments_comment"
                  >
                    <div class="parent-userDplabl mb-2 ml-2">
                      <div class="mr-3">
                        <vs-avatar
                          v-if="comment.usersDetails_UserImage != null"
                          class="m-0"
                          :src="magittUrl + comment.usersDetails_UserImage"
                          size="30px"
                          :text="comment.usersDetails_UserName"
                        />
                        <vs-avatar
                          v-if="comment.usersDetails_UserImage == null"
                          class="bg-primary"
                          size="30px"
                          :text="comment.usersDetails_UserName"
                        />
                      </div>
                      <div class="smp_leading_tight smp_textsocial">
                        <p
                          class="font-medium h4-class smp_cursor-pointer cust-pt-pb cust-pb-5"
                        >
                          {{ comment.usersDetails_UserName }}
                        </p>
                        <span class="post-comm-spn">{{
                          comment.replies_comment
                        }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class>
                  <div class="replybtn-parent flex cursor-pointer mb-2">
                    <div class="reply-btn" v-if="!showReply">
                      <a @click.stop="showReply = true">Reply</a>
                    </div>

                    <div class="ml-8 cursor-pointer mb-2">
                      <div
                        class="reply-btn"
                        v-if="groupPost.REPLYCOUNT > 0 && !showReplies"
                      >
                        <a
                          @click.stop="
                            getFirstReplies(groupPost.first_comment_id)
                          "
                          >({{ groupPost.REPLYCOUNT }}) Replies</a
                        >
                      </div>
                    </div>
                  </div>

                  <div class="replybtn-input ml-10 relative flex">
                    <vs-input
                      v-if="showReply"
                      size="small"
                      class="reply-inputbox"
                      placeholder
                      v-model="firstReply"
                      @keydown.enter="
                        addReplyFirstComment(
                          groupPost.post_UniqueId,
                          groupPost.first_comment_id,
                          firstReply
                        )
                      "
                    />
                    <!-- <vs-button
                        class="ml-2"
                        size="small"
                        v-if="showReply"
                        @click="
                          addReplyFirstComment(
                            groupPost.post_UniqueId,
                            groupPost.first_comment_id,
                            firstReply
                          )
                        "
                        >Reply</vs-button
                    >-->
                    <div class="indiv-repindivreply-sendbtn">
                      <i
                        icon="icon-send"
                        class="feather icon-send feather-20 text-primary"
                        v-if="showReply"
                        @click="
                          addReplyFirstComment(
                            groupPost.post_UniqueId,
                            groupPost.first_comment_id,
                            firstReply
                          )
                        "
                      ></i>
                    </div>
                  </div>
                </div>
              </li>

              <li
                class="smp_commented_user items-center"
                v-for="comment of groupPost.comments"
                :key="comment.comments_commentsId"
              >
                <div class="parent-userDplabl">
                  <div class="mr-3">
                    <vs-avatar
                      v-if="comment.usersDetails_UserImage != null"
                      class="m-0"
                      :src="magittUrl + comment.usersDetails_UserImage"
                      size="30px"
                      :text="comment.usersDetails_UserName"
                    />
                    <vs-avatar
                      v-if="comment.usersDetails_UserImage == null"
                      class="bg-primary"
                      size="30px"
                      :text="comment.usersDetails_UserName"
                    />
                    <!-- <vs-avatar
                      class="m-0"
                      :src="magittUrl + comment.usersDetails_UserImage"
                      size="30px"
                    />-->
                  </div>
                  <div class="smp_leading_tight smp_textsocial">
                    <p
                      class="font-medium h4-class smp_cursor-pointer cust-pt-pb cust-pb-5"
                    >
                      {{ comment.usersDetails_UserName }}
                    </p>
                    <span class="post-comm-spn">{{
                      comment.comments_comment
                    }}</span>
                  </div>
                </div>
                <ul class="smp_user-comments-list" v-if="comment.showReplies">
                  <li
                    class="smp_commented_user items-center ml-2 smp_text_pddd"
                    v-for="comment of comment.replies"
                    :key="comment.replies_id"
                  >
                    <div class="parent-userDplabl">
                      <div class="mr-3">
                        <vs-avatar
                          v-if="comment.usersDetails_UserImage != null"
                          class="m-0"
                          :src="magittUrl + comment.usersDetails_UserImage"
                          size="30px"
                          :text="comment.usersDetails_UserName"
                        />
                        <vs-avatar
                          v-if="comment.usersDetails_UserImage == null"
                          class="bg-primary"
                          size="30px"
                          :text="comment.usersDetails_UserName"
                        />
                      </div>
                      <div class="smp_leading_tight smp_textsocial">
                        <p
                          class="font-medium h4-class smp_cursor-pointer cust-pt-pb cust-pb-5"
                        >
                          {{ comment.usersDetails_UserName }}
                        </p>
                        <span class="post-comm-spn">{{
                          comment.replies_comment
                        }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <div class>
                  <div class="flex">
                    <div class="replybtn-parent cursor-pointer mb-2">
                      <div class="reply-btn" v-if="!comment.showReply">
                        <a @click.stop="comment.showReply = true">Reply</a>
                      </div>
                    </div>
                    <div class="replybtn-parent cursor-pointer mb-2">
                      <div
                        class="reply-btn"
                        v-if="comment.REPLYCOUNT > 0 && !comment.showReplies"
                      >
                        <a @click.stop="getReplies(comment.comments_commentsId)"
                          >({{ comment.REPLYCOUNT }}) Replies</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="replybtn-input ml-10 relative flex">
                    <vs-input
                      v-if="comment.showReply"
                      size="small"
                      class="reply-inputbox"
                      placeholder
                      v-model="comment.newReply"
                      @keydown.enter="
                        addReply(
                          groupPost.comments_postId,
                          comment.comments_commentsId,
                          comment.newReply
                        )
                      "
                    />
                    <div class="indiv-repindivreply-sendbtn">
                      <i
                        icon="icon-send"
                        class="feather icon-send feather-20 text-primary"
                        v-if="comment.showReply"
                        @click="
                          addReply(
                            groupPost.comments_postId,
                            comment.comments_commentsId,
                            comment.newReply
                          )
                        "
                      ></i>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="mb-4 lg:w-1/2 md:w-1/2 w-full mb-8 relative">
            <vs-input
              class="rounded-lg w-full inputx"
              placeholder="write a comment...."
              v-model="groupPost.newComment"
              @keydown.enter="
                addComment(
                  groupPost.post_UniqueId,
                  groupPost.newComment,
                  groupPost.usersDetails_UniqueId
                )
              "
            />
            <div class="individualsend_icon_setting">
              <a v-if="groupPost.newComment != ''" class>
                <i
                  class="feather icon-send feather-20 text-primary"
                  icon="icon-send"
                  @click="
                    addComment(
                      groupPost.post_UniqueId,
                      groupPost.newComment,
                      groupPost.usersDetails_UniqueId
                    )
                  "
                ></i>
              </a>
            </div>
          </div>
          <div class="lg:flex md:flex gap-8">
            <a
              href="https://api.whatsapp.com/send?phone=912266006022&text=Subscribe"
              target="_blank"
              class="whatsapp-color flex items-center px-4 py-3 sm-w60 rounded-sm lg:mb-0 md:mb-0 mb-4"
            >
              <span class="text-white fsize12 font-medium mr-2"
                >Get updates on WhatsApp</span
              >
              <img
                src="@/Core/assets/images/pages/whatsappicon.png"
                class="w-10 h-10"
                alt="ss"
              />
            </a>

            <!-- <a
              :href="magittUrl+groupPost.post_attachedDocument"
              target="_blank"
              class="pdf-color flex items-center sm-w45 px-1 py-1 rounded-sm"
            >
              <img
                src="@/Core/assets/images/pages/post/PDF_file_icon.svg"
                class="w-10 h-10"
                alt="ss"
              />
              <span class="text-primary fsize12 font-medium my-4 mx-6"
                >Download File</span
              >
            </a> -->
          </div>
        </div>
        <div class="lg:w-1/3 md:w-1/3 lg:pr-6 md:pr-6 pr-0">
          <EcoRight :postData="groupPost" />
        </div>
      </div>
    </div>

    <div class="">
      <SubscribeBanner />
    </div>
  </div>
</template>
<script>
// import SubscribeBanner from "../homepage/components/SubscribeBanner";
import EcoLeft from "./components/EcoLeft.vue";
import EcoRight from "./components/EcoRight.vue";
import Banner from "./components/Banner";

import "video.js/dist/video-js.css";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const postsRepository = RepositoryFactory.get("posts");
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import LinkPrevue from "link-prevue";
require("videojs-youtube");
import * as moment from "moment";
import PostComment from "../components/PostComment.vue";
const notificationRepository = RepositoryFactory.get("notifications");
const promotionsRepository = RepositoryFactory.get("promotions");
import PollView from "../../group/components/PollView.vue";
// import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
// import DownloadDocuments from "@/Core/Views/CommonPlugins/documents/DownloadDocuments";

export default {
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        {
          name: "description",
          content: this.metaDescription ? this.metaDescription : this.metaTitle,
        },
        {
          property: "og:title",
          content: this.metaTitle,
        },
        {
          property: "og:image",
          content: this.metaImage,
        },
        {
          property: "og:url",
          content: `${window.location.href}`,
        },
        { property: "og:type", content: "website" },
      ],
    };
  },
  data() {
    return {
      value1: "",
      queryParams: {},
      token: {},
      groupPost: {},
      text: window.location.href,
      firstReply: "",
      showReply: false,
      showReplies: false,
      displayName: "Kotak Treasury",
      isLoaded: false,
      postIDFromShortURL: null,
      viewUniqueId: null,
    };
  },
  methods: {
    openLoadingContained() {
      this.$vs.loading({
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45,
      });
      setTimeout(() => {
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
      }, 3000);
    },
    getPost() {
      let obj = {
        userData: {
          id: this.token.userID,
          uuid: this.token.uuid,
        },
        groupData: {
          id: this.queryParams.postId,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      // console.log(obj);
      return new Promise(async (resolve, reject) => {
        try {
          this.$vs.loading();

          const { data } = await postsRepository.getPost(obj);

          // console.log(data);

          if (data) {
            this.postLoaded = true;
            //        ////debugger
            this.$vs.loading.close();
            if (data.length > 0) {
              if (data[0].post_isDeleted == 1) {
                this.$router.push("/pages/PostNotFound");
              } else {
                data.forEach((element) => {
                  // console.log("bbbdf", element);
                  // if (
                  //   moment().diff(
                  //     moment(element.post_group_mapping_created_datetimestamp),
                  //     "hours"
                  //   ) < 13
                  // ) {
                  element.post_group_mapping_created_datetimestamp = moment(
                    element.post_group_mapping_created_datetimestamp
                  ).fromNow();
                  // } else {
                  //   element.post_group_mapping_created_datetimestamp = moment(
                  //     element.post_group_mapping_created_datetimestamp
                  //   ).format("DD-MM-YYYY HH:mm");
                  // }
                  if (element.image != null) {
                    if (element.image.includes("uploads/postImages")) {
                      element.image = this.magittUrl + element.image;
                    }
                  }
                  if (element.video_url != null) {
                    if (element.video_url.includes("uploads/postVideos")) {
                      element.video_url = this.magittUrl + element.video_url;
                    }
                  }
                  if (element.audio_url != null) {
                    if (element.audio_url.includes("uploads/postAudios")) {
                      element.audio_url = this.magittUrl + element.audio_url;
                    }
                  }
                  if (element.featured_image != null) {
                    if (element.featured_image.includes("uploads/postImages")) {
                      element.featured_image =
                        this.magittUrl + element.featured_image;
                    }
                  }
                  if (element.PollQuestion !== null) {
                    // console.log(element.VotedIds, "fddfbb");
                    element.VotedIds =
                      element.VotedIds !== null ? element.VotedIds : "";
                    element.AnswersIds =
                      element.AnswersIds !== null
                        ? element.AnswersIds.split(",")
                        : [];
                    element.PollAnswers =
                      element.PollAnswers !== null
                        ? element.PollAnswers.split(",")
                        : [];
                    element.AnswersVotes =
                      element.AnswersVotes !== null
                        ? element.AnswersVotes.split(",")
                        : [];
                  }
                  element.comments = [];
                  element.newComment = "";
                  element.comments = [];
                  element.newComment = "";
                  if (!this.$acl.check("admin")) {
                    if (element.first_comment_user_id != this.token.userID) {
                      element.first_comment = null;
                      element.REPLYCOUNT = 0;
                    } else {
                      element.first_comment_replies = [];
                    }
                  } else {
                    element.first_comment_replies = [];
                  }
                  this.groupPost = element;
                });
              }
              // console.log(this.groupPost);
              resolve(true);
            } else {
              //       ////debugger
              resolve(false);
            }
          } else {
            resolve(false);
          }
        } catch (error) {
          this.$vs.loading.close();
          reject(error);
        }
      });
    },
    async getComments() {
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: this.queryParams.postId,
        limit: 10000,
        offset: 1,
      };

      const { data } = await postsRepository.getComments(obj);

      if (data.ResponseCode == 100) {
        // if (!this.$acl.check("admin")) {
        //   this.groupPost.comments = data.Data.filter(x => x.comments_userId != this.token.uuid)
        // } else {
        //   this.groupPost.comments = data.Data;
        // }
        data.Data.forEach((element) => {
          if (!this.$acl.check("admin")) {
            if (element.comments_userId == this.token.uuid) {
              element.showReply = false;
              element.showReplies = false;
              element.newReply = "";
              element.replies = [];
              this.groupPost.comments.push(element);
            }
          } else {
            element.showReply = false;
            element.showReplies = false;
            element.newReply = "";
            element.replies = [];
            this.groupPost.comments.push(element);
          }
        });
      }
    },
    async likePost(status, postId, likeId, userId, postuid, uuid) {
      if (likeId) {
        this.$vs.loading();
        let self = this;
        let payload = {
          likeData: {
            id: likeId,
            isActive: !status,
            ownerName: self.token.username,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await postsRepository.editLikedPost(payload);

        if (data.ResponseCode == 100) {
          this.$vs.loading.close();
          this.groupPost.is_active = !status;
          if (!status) {
            this.groupPost.LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPost.LIKECOUNT -= 1;
          }
        } else {
          this.$vs.loading.close();
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } else {
        this.$vs.loading();
        let self = this;
        let payload = {
          likeData: {
            postId: postuid,
            groupId: null,
            userId: self.token.uuid,
            isActive: 1,
            ownerName: self.token.username,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        const { data } = await postsRepository.likePost(payload);

        if (data.ResponseCode == 100) {
          this.$vs.loading.close();
          this.groupPost.is_active = 1;
          this.groupPost.likeId = data.insertId;
          if (!status) {
            this.groupPost.LIKECOUNT += 1;
            this.insertLikeNotification(postuid, uuid);
          } else {
            this.groupPost.LIKECOUNT -= 1;
          }
        } else {
          this.$vs.loading.close();
          self.$vs.notify({
            title: "Error",
            text: "Something went wrong",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },
    async insertLikeNotification(postId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "liked your post",
            notificationTitle: "Likes",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        await notificationRepository.addNotification(payload);
      }
    },
    async addComment(postId, comment, uuid) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        userId: this.token.uuid,
        comment: comment,
        commenterName: this.token.username,
      };

      const { data } = await postsRepository.insertComment(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        if (!this.groupPost.first_comment) {
          this.groupPost.first_comment = comment;
          this.groupPost.first_comment_user_name = this.token.username;
          this.groupPost.first_comment_user_image = this.token.userImage;
        } else {
          this.groupPost.comments.push({
            usersDetails_UserImage: this.token.userImage,
            usersDetails_UserName: this.token.username,
            comments_comment: comment,
          });
        }
        this.insertCommentNotification(postId, uuid);
        this.groupPost.newComment = "";
        this.groupPost.comment_count =
          this.groupPost.comment_count == null
            ? 1
            : parseInt(this.groupPost.comment_count) + 1;
      } else {
        this.$vs.loading.close();
      }
    },
    async insertCommentNotification(postId, userId) {
      if (this.token.uuid != userId) {
        let payload = {
          notificationData: {
            initiaterId: this.token.uuid,
            initiaterName: this.token.username,
            initiaterImage: this.token.userImage,
            recepientId: userId,
            recepientType: 1,
            notificationMessage: "commented on your post",
            notificationTitle: "Comments",
            notificationEntityType: "Post",
            notificationEntityId: postId,
            notificationReadStatus: 0,
          },
          communityData: {
            communityName: currentCommunityName.communityName,
          },
        };
        await notificationRepository.addNotification(payload);
      }
    },
    async addReplyFirstComment(postId, commentId, reply) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        commentId: commentId,
        reply: reply,
        commenterName: this.token.username,
        userId: this.token.uuid,
      };

      const { data } = await postsRepository.insertReply(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        this.groupPost.first_comment_replies.push({
          usersDetails_UserImage: this.token.userImage,
          usersDetails_UserName: this.token.username,
          replies_comment: reply,
        });
        this.showReplies = true;
        this.getFirstReplies(commentId);
        this.firstReply = "";
      } else {
        this.$vs.loading.close();
      }
    },
    async addReply(postId, commentId, reply) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        commentId: commentId,
        reply: reply,
        commenterName: this.token.username,
        userId: this.token.uuid,
      };

      const { data } = await postsRepository.insertReply(obj);

      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        this.groupPost.comments[
          this.groupPost.comments.findIndex(
            (x) => x.comments_commentsId == commentId
          )
        ].replies.push({
          usersDetails_UserImage: this.token.userImage,
          usersDetails_UserName: this.token.username,
          replies_comment: reply,
        });
        this.groupPost.comments[
          this.groupPost.comments.findIndex(
            (x) => x.comments_commentsId == commentId
          )
        ].showReplies = true;
        this.groupPost.comments[
          this.groupPost.comments.findIndex(
            (x) => x.comments_commentsId == commentId
          )
        ].newReply = "";
      } else {
        this.$vs.loading.close();
      }
    },
    async getFirstReplies(commentId) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        commentId: commentId,
      };

      const { data } = await postsRepository.getReplies(obj);

      if (data.ResponseCode == 100) {
        // if (!this.$acl.check("admin")) {
        //   this.groupPost.comments = data.Data.filter(x => x.comments_userId != this.token.uuid)
        // } else {
        //   this.groupPost.comments = data.Data;
        // }
        this.$vs.loading.close();
        this.showReplies = true;
        data.Data.forEach((element) => {
          this.groupPost.first_comment_replies.push(element);
        });
      } else {
        this.$vs.loading.close();
      }
    },
    async getReplies(commentId) {
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        commentId: commentId,
      };

      const { data } = await postsRepository.getReplies(obj);

      if (data.ResponseCode == 100) {
        // if (!this.$acl.check("admin")) {
        //   this.groupPost.comments = data.Data.filter(x => x.comments_userId != this.token.uuid)
        // } else {
        //   this.groupPost.comments = data.Data;
        // }
        this.$vs.loading.close();
        this.groupPost.comments[
          this.groupPost.comments.findIndex(
            (x) => x.comments_commentsId == commentId
          )
        ].showReplies = true;
        data.Data.forEach((element) => {
          this.groupPost.comments[
            this.groupPost.comments.findIndex(
              (x) => x.comments_commentsId == commentId
            )
          ].replies.push(element);
        });
      } else {
        this.$vs.loading.close();
      }
    },
    async addViewedPromotionLog() {
      let payload = {
        communityName: currentCommunityName.communityName,
        userId: this.token.uuid,
        postId: this.queryParams.postId,
        name: this.token.username,
        startTime: new Date().toISOString().replace("T", " ").replace("Z", ""),
      };
      const { data } = await promotionsRepository.addViewedPromotion(payload);
      if (data) {
        this.viewUniqueId = data.uniqueId;
      }
    },
    async updateViewedPromotionLog() {
      let payload = {
        communityName: currentCommunityName.communityName,
        uniqueId: this.viewUniqueId,
        endTime: new Date().toISOString().replace("T", " ").replace("Z", ""),
      };
      promotionsRepository.updateViewedPromotion(payload);
    },
    async updateCRNLog() {
      if (this.queryParams.crn) {
        let payload = {
          crn: this.queryParams.crn,
          communityData: {
            communityName: "Kotak Treasury",
          },
          postid: this.queryParams.postId,
        };
        postsRepository.updatePostByCRN(payload);
      }
      // promotionsRepository.updateViewedPromotion(payload);
    },
    async addSharedPromotionLog(medium) {
      let payload = {
        communityName: currentCommunityName.communityName,
        userId: this.token.uuid,
        postId: this.queryParams.postId,
        medium: medium,
        name: this.token.username,
      };
      // console.log(payload);
      await promotionsRepository.addSharedPromotion(payload);
    },
    socialShareOpen(network) {
      this.addSharedPromotionLog(network);
    },
    getParams(fullurl) {
      const url = new URL(fullurl);
      return url.searchParams.get("pid");
    },
    getFullURL() {
      this.$vs.loading();
      let payload = {
        shortUrl: this.$route.params.id,
      };
      this.$store.dispatch("shortURL/getFullUrl", payload).then(() => {
        this.$vs.loading.close();
        const fullurl = this.$store.state.shortURL.url.full;

        this.postIDFromShortURL = this.getParams(fullurl);
        this.loadContent();
        //window.location.replace(this.$store.state.shortURL.url.full);
      });
    },
    getShortURL() {
      let payload = {
        full: window.location.href,
      };
      this.$store.dispatch("shortURL/addShortUrl", payload).then(() => {
        this.text = `${window.document.location.origin}/readers-view/${this.$store.state.shortURL.url.short}`;
      });
      // console.log(this.$childrentext);
    },
    loadContent() {
      // console.log("loadContent");
      const postID = this.postIDFromShortURL
        ? this.postIDFromShortURL
        : this.$route.query.pid;
      const crn = this.postIDFromShortURL
        ? this.postIDFromShortURL
        : this.$route.query.crn;
      if (localStorage.getItem("token")) {
        setTimeout(() => {
          if (this.$router.currentRoute.params.groupPost) {
            this.groupPost = this.$router.currentRoute.params.groupPost;
          } else if (postID) {
            this.queryParams.postId = postID;
            this.queryParams.crn = crn;
            this.getPost().then((data) => {
              if (data) {
                this.getComments(this.groupPost.post_UniqueId);

                // Dont generate new short ID if already present
                if (!this.$route.params.id) {
                  this.getShortURL();
                }

                this.addViewedPromotionLog();
                if (crn) {
                  this.updateCRNLog();
                }
              }
            });
          }
          this.isLoaded = true;
        }, 1000);
      } else {
        // console.log("sdfghjkldfgh");
        this.$router
          .push({
            name: "page-kotak-login",
            params: {
              toIndividualPost: true,
              pid: postID,
              crn: crn,
            },
          })
          .catch(() => {});
      }
    },
  },
  mounted() {
    // // this.$_mountTime = date.now();
    window.onbeforeunload = window.addEventListener(
      "beforeunload",
      this.updateViewedPromotionLog
    );
  },
  created() {
    if (this.$route.params.id) {
      // console.log("testing if");
      this.getFullURL();
    } else {
      // console.log("testing else");
      this.loadContent();
    }

    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
  },
  components: {
    Banner,
    PollView,
    // SubscribeBanner,
    EcoLeft,
    EcoRight,
    PostComment,
  },
};
</script>
<style>
.icon-size {
  width: 20px;
  height: 20px;
}
.whatsapp-color {
  background: #25d366;
}
.pdf-color {
  background-color: #dbdbdb;
}
.circle {
  width: 50px;
  height: 50px;
  background: #808080a8;

  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  color: black;
  font-weight: 700;
}
.vs-input--placeholder.normal {
  padding: 0.8rem;
}
@media (max-width: 641px) {
  .sm-w60 {
    width: 61% !important;
    margin: 0.5rem auto !important;
  }
  .sm-w45 {
    width: 45% !important;
    margin: 0.5rem auto !important;
  }
}
</style>
