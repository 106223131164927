<template>
  <div class="mt-4">
    <div class="smp_comments_container smp_mt_4" v-if="Comments.length > 0">
      <ul class="smp_user-comments-list post__comment">
        <li
          class="smp_commented_user items-center smp_text_pddd mb-4"
          v-for="comment in Comments"
          :key="comment.comments_id"
        >
          <div class="parent-userDplabl">
            <div class="mr-1">
              <UserAvatar
                :image="comment.usersDetails_UserImage"
                :text="comment.comments_commentedBy"
                :userID="comment.usersDetailsId"
                @imageNotFound="comment.usersDetails_UserImage = null"
              />
            </div>
          
           
            <div class="smp_leading_tight smp_textsocial">
              <div class="flex">
                <p
                  class="font-semibold h4-class smp_cursor-pointer cust-pt-pb w-full"
                >
                  {{ comment.comments_commentedBy }}
                </p>

<div class="" >
  
  <vs-dropdown vs-trigger-click >
                  <a class="flex items-center" href="">
                    <i class="material-icons cust-selfhide ml-2">expand_more</i>
                  </a>

                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="confirmDeleteComment(comment)"
                      >Delete</vs-dropdown-item
                    >
                  </vs-dropdown-menu>
                </vs-dropdown>
</div>
              </div>
              <span class="post-comm-spn">{{ comment.comments_comment }}</span>
            </div>
          </div>
          <ul class="smp_user-comments-list ml-6" v-if="comment.showReplies">
            <li
              class="smp_commented_user items-center smp_text_pddd"
              v-for="comment in comment.replies"
              :key="comment.replies_id"
            >
              <div class="parent-userDplabl m-2">
                <div class="mr-1">
                  <UserAvatar
                    :image="comment.usersDetails_UserImage"
                    :text="comment.usersDetails_UserName"
                    :userID="comment.usersDetailsId"
                    @imageNotFound="comment.usersDetails_UserImage = null"
                  />
                </div>
                <div class="smp_leading_tight smp_textsocial">
              
              
                  <div class="flex">
                    <p
                      class="font-semibold h4-class smp_cursor-pointer cust-pt-pb w-full"
                    >
                      {{ comment.usersDetails_UserName }}
                    </p>
                   

                    <vs-dropdown vs-trigger-click>
                      <a class="flex items-center" href="">
                        <i class="material-icons cust-selfhide ml-2"
                          >expand_more</i
                        >
                      </a>

                      <vs-dropdown-menu>
                        <vs-dropdown-item @click="confirmDeleteReply(comment)"
                          >Delete</vs-dropdown-item
                        >
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </div>
                  <span class="post-comm-spn">
                    {{ comment.replies_comment }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <div class="replybtn-parent cursor-pointer mb-2">
            <div class="">
              <span class="textmute-usermes h4-class">
                {{ comment.commentTime }}
              </span>
            </div>
            <div class="flex">
              <div class="reply-btn">
                <a @click.stop="setFocusOnReply(comment)">Reply</a>
              </div>
              <div class="cursor-pointer ml-2">
                <div class="ml-3 mb-2 reply-btn" v-if="comment.REPLYCOUNT > 0">
                  <a
                    v-if="!comment.showReplies"
                    @click.stop="
                      getReplies(comment.comments_commentsId);
                      comment.showReplies = true;
                    "
                    >View {{ comment.REPLYCOUNT }}
                    {{ comment.REPLYCOUNT == 1 ? "Reply" : "Replies" }}</a
                  >
                </div>
              </div>
            </div>
            <div
              class="replybtn-input flex relative"
              v-if="comment.replyShow == true"
            >
              <div class="pt-2">
                <UserAvatar
                  :image="$store.state.user.userImage"
                  :text="$store.state.user.username"
                  :userID="$store.state.user.userID"
                  :size="'35px'"
                  @imageNotFound="$store.dispatch('storeUserImage', null)"
                />
              </div>
              <vs-input
                size="small"
                class="reply-inputbox"
                placeholder
                v-model="comment.reply"
                @blur="comment.replyShow = false"
                @keydown.enter="
                  addReply(
                    comment.post_UniqueId,
                    comment.comments_commentsId,
                    comment.reply
                  )
                "
              />
              <div class="indivreply-sendbtn">
                <i
                  icon="icon-send"
                  class="feather icon-send feather-20 text-primary"
                  @click="
                    addReply(
                      comment.post_UniqueId,
                      comment.comments_commentsId,
                      comment.reply
                    )
                  "
                ></i>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div
        class="pd-10 flex justify-between"
        v-if="
          post.comment_count != null && post.comment_count > Comments.length
        "
      >
        <span
          @click="LoadMoreComments(5)"
          v-show="showMoreComment"
          class="view-morecm cursor-pointer"
          >View more comments</span
        >
        <span class="view-morecount font-medium " v-if="Comments.length > 0"
          >{{ Comments.length }} of {{ post.comment_count }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
import UserAvatar from "@/Core/Views/CommonPlugins/user-avatar/UserAvatar";
const postsRepository = RepositoryFactory.get("posts");
import * as moment from "moment";
export default {
  name: "post-comment",
  components: {
    UserAvatar
  },
  data() {
    return {
      CommentOffset: 0,
      token: null,
      Reply: null,
      replies: [],
      showMoreComment: true,
      PrevInput: null
    };
  },
  props: {
    post: { type: Object, default: () => {} }
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
    Comments() {
      return this.post.comments;
    }
  },
  methods: {
    setFocusOnReply(setFocus) {
      setFocus.replyShow = !setFocus.replyShow;
      // if (this.PrevInput == null) {
      //   // this.PrevInput.replyShow = true;
      //   this.PrevInput = setFocus;
      // } else {
      //   console.log(this.PrevInput);
      //   this.PrevInput.replyShow = false;
      //   this.PrevInput = setFocus;
      // }
    },
    async LoadMoreComments(limit) {
      if (limit == 5) {
        this.$vs.loading();
      }

      let payload = {
        communityName: currentCommunityName.communityName,
        postId: this.post.postId,
        limit: limit,
        offset: this.CommentOffset
      };
      let { data } = await postsRepository.getComments(payload);
      if (data.ResponseCode == 100) {
        this.$vs.loading.close();
        if (data.Data.length == 0) {
          this.showMoreComment = false;
        }
        // console.log(data);
        data.Data = data.Data.map(el => ({
          ...el,
          replyShow: false,
          replies: [],
          showReplies: false,
          reply: "",
          commentTime: this.formatCommentTime(
            moment(el.comment_createdDate).fromNow(true)
          )
        }));
        this.CommentOffset = this.CommentOffset + Number(limit);
        this.post.comments = [...this.post.comments, ...data.Data];
        // console.log(this.Comments);
      } else {
        this.$vs.loading.close();
      }
    },
    async getReplies(commentId) {
      // this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        commentId: commentId
      };

      const { data } = await postsRepository.getReplies(obj);
      // console.log(data);
      if (data.ResponseCode == 100) {
        // this.replies = [...this.replies, ...data.Data];
        data.Data.forEach(element => {
          let findData = this.Comments[
            this.Comments.findIndex(x => x.comments_commentsId == commentId)
          ];
          findData.replies.push(element);
        });
      }
    },
    async addReply(postId, commentId, reply) {
      //insertReply
      this.$vs.loading();
      let obj = {
        communityName: currentCommunityName.communityName,
        postId: postId,
        commentId: commentId,
        reply: reply,
        commenterName: this.token.username,
        userId: this.token.uuid
      };

      const { data } = await postsRepository.insertReply(obj);

      if (data.ResponseCode == 100) {
        //   console.log(data);
        this.$vs.loading.close();
        let findComment = this.Comments[
          this.Comments.findIndex(x => x.comments_commentsId == commentId)
        ];
        this.getReplies(commentId);
        findComment.replies = [];
        findComment.showReplies = true;
        findComment.reply = "";
        this.$vs.loading.close();
      }
    },
    formatCommentTime(time) {
      if (time) {
        if (time.includes("seconds")) {
          return time.replace("seconds", "s");
        } else if (time.includes("minutes")) {
          return time.replace("minutes", "m");
        } else if (time.includes("hours")) {
          return time.replace("hours", "h");
        } else if (time.includes("days")) {
          return time.replace("days", "d");
        } else {
          return time;
        }
      }
    },
    async confirmAlert() {
      return this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      });
    },
    async confirmDeleteComment(comment) {
      this.confirmAlert().then(result => {
        if (result.value) {
          this.deleteComment(comment);
        }
      });
    },
    async confirmDeleteReply(reply) {
      this.confirmAlert().then(result => {
        if (result.value) {
          this.deleteReply(reply);
        }
      });
    },
    deleteComment(comment) {
      this.$vs.loading();
      const payload = {
        communityName: currentCommunityName.communityName,
        status: 1,
        name: this.token.username,
        commentId: comment.comments_commentsId
      };

      this.$store.dispatch("post/deletePostComment", payload).then(data => {
        if (data) {
          this.$vs.loading.close();
          const index = this.post.comments.findIndex(
            x => x.comments_commentsId == comment.comments_commentsId
          );
          this.post.comments.splice(index, 1);
        }
      });
    },
    deleteReply(reply) {
      this.$vs.loading();
      const payload = {
        communityName: currentCommunityName.communityName,
        status: 1,
        name: this.token.username,
        replyId: reply.replies_repliesId
      };

      this.$store.dispatch("post/deletePostReply", payload).then(data => {
        if (data) {
          this.$vs.loading.close();
          const index = this.post.comments.findIndex(
            x => x.replies_commentId == reply.replies_commentId
          );
          const replyIndex = this.post.comments[index].replies.findIndex(
            x => x.replies_repliesId == reply.replies_repliesId
          );
          this.post.comments[index].replies.splice(replyIndex, 1);
        }
      });
    }
  },
  mounted() {
    this.post.comments = [];
    this.LoadMoreComments("1");
  },
  created() {
    let newtoken = localStorage.getItem("token");
    this.token = jwt.decode(newtoken);
  }
};
</script>

<style></style>
