<template>
  <div>
    <!-- <iframe
      class="w-full"
      height="500"
      :src="channelPostDetail.video_url"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe> -->

    <img
      class="indiv-postimg"
      :src="postData.image"
      @error="
        postData.image = magittUrl + 'uploads/defaultImages/default_post.png'
      "
      alt="user-upload"
      v-if="postData.image != null"
      @click="
        group_page_post_sidebar_active = !group_page_post_sidebar_active;
        postPopupImage = postData.image;
      "
    />
    <img
      class="indiv-postimg"
      v-if="postData.blogImage != null"
      :src="magittUrl + postData.blogImage"
      @error="
        postData.featured_image =
          magittUrl + 'uploads/defaultImages/default_post.png'
      "
      alt="user-upload"
      @click.stop="
        group_page_post_sidebar_active = !group_page_post_sidebar_active;
        postPopupImage = postData.featured_image;
      "
    />
    <div class="flex justify-around my-5" v-if="postData.blogPDF != null">
      <DownloadDocuments :path="postData.blogPDF" />
    </div>
    <video-player
      ref="player"
      class
      :options="playerOptions(postData)"
      v-if="postData.video_url != null"
      v-once
    />
    <video-player
      ref="player"
      class
      :options="playerOptionsBlog(postData)"
      v-if="postData.blogVideo != null"
      v-once
    />
    <audio controls v-if="postData.audio_url != null">
      <source :src="postData.audio_url" />
    </audio>
    <!-- <PollView
      v-show="postData.PollQuestionId"
      :votedIds="postData.VotedIds"
      :PostId="postData.post_group_mapping_post_id"
      :PollId="postData.PollQuestionId"
      :Ids="postData.AnswersIds"
      :Question="postData.PollQuestion"
      :Answers="postData.PollAnswers"
      :AnswersVotes="postData.AnswersVotes"
    /> -->
    <p
      v-show="postData.text != null"
      v-html="postData.text"
      class="smp_text_pddd h3-class p-3"
    ></p>
    <p
      v-if="postData.imageText"
      v-html="postData.imageText"
      class="smp_text_pddd"
    ></p>
    <p
      v-if="postData.videoText"
      v-html="postData.videoText"
      class="smp_text_pddd"
    ></p>
    <p
      v-if="postData.audioText"
      v-html="postData.audioText"
      class="smp_text_pddd"
    ></p>
    <h3
      v-if="postData.blogText"
      v-html="postData.body"
      class="smp_text_pddd"
    ></h3>
    <div
            class="flex justify-around my-5"
            v-if="postData.post_attachedDocument != null"
          >
            <DownloadDocuments :path="postData.post_attachedDocument" />
          </div>   
    <!-- <div class="mt-6">
      <p class="text-303030 font-medium lheight-h28 fsize16">
        Last Wednesday, the US Federal Reserve increased its benchmark rate by
        75 basis points. This is the third time the Fed has hiked the federal
        funds rate by 75 basis points this year. It now stands at 3-3.25%, the
        highest since January 2008. Retail inflation in the US is at a record
        high. Therefore, the idea is to increase rates, dampen consumer demand,
        and slow inflation.
      </p>

      <div class="mt-8">
        <h4 class="font-semibold fsize20">A rate hike likely</h4>
        <p class="text-303030 font-medium lheight-h28 mt-6 fsize16">
          However, as the dollar derives strength from US Fed’s aggressive rate
          stance, the Indian central bank’s monetary policy committee is likely
          to raise interest rates by 50 bps. “For now, the repo rate could touch
          ~5.90-6% and see a pause before more hikes. There is an outer chance
          of another 25-bps hike if RBI would want to err on the side of
          caution,” said Lakshmi Iyer, President, CIO-Debt & Head-Products –
          Kotak Mahindra Asset Management Co.
        </p>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { videoPlayer } from "vue-video-player";
import DownloadDocuments from "@/Core/Views/CommonPlugins/documents/DownloadDocuments";
import PollView from "../../../group/components/PollView.vue";

export default {
  components: {
    videoPlayer,
    DownloadDocuments,
    PollView,
  },
  props: {
    postData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState("post", ["channelPostDetail"]),
    magittUrl() {
      return this.baseUrlMagitt;
    },
    playerOptions() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: media.video_url }],
          poster: media.poster,
        };
      };
    },
    playerOptionsBlog() {
      return (media) => {
        return {
          height: "360",
          fluid: true,
          autoplay: false,
          muted: false,
          language: "en",
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{ src: this.magittUrl + media.blogVideo }],
          poster: media.poster,
        };
      };
    },
  },
  mounted() {
    // window.location.reload()
    // console.log('vvvv',this.postData);
    // console.log(this.channelPostDetail);
  },
};
</script>
