<template>
  <div class="relative">
    <div class="w-full banner-h500px sm-banner350px bg-primary">
      <img
        v-if="postData.featured_image"
        :src="postData.featured_image"
        @error="
          postData.featured_image = '@/Core/assets/images/home/ecobanner.png'
        "
        alt="image"
        class="w-full banner-h500px sm-banner350px object-cover"
      />
      <img
        v-else
        src="@/Core/assets/images/home/ecobanner.png"
        alt="image"
        class="w-full banner-h500px sm-banner350px object-cover"
      />
    </div>

    <div
      class="absolute h-full items-end lg:top-12 top-2 top-0 left-0 lg:py-16 md:py-10 py-5 left-0 lg:px-24 md:px-24 px-5 w-full lg:flex md:flex block"
    >
      <div class="w-full pb-4">
        <span class="cursor-pointer font-bold text-primary cursor-pointer"
          >{{ postData.post_category }}
        </span>

        <h2
          class="font-bold min-fsize40 text-white py-4 lg:w-3/5 md:w-8/12 w-full sm-fszie22px"
        >
          <p
            v-if="postData.imageText"
            v-html="postData.imageText"
            class="Grp-post-content"
          ></p>
          <p
            v-if="postData.videoText"
            v-html="postData.videoText"
            class="Grp-post-content"
          ></p>
          <p
            v-if="postData.audioText"
            v-html="postData.audioText"
            class="Grp-post-content"
          ></p>
          <p
            v-if="postData.blogText"
            v-html="postData.blogText"
            class="Grp-post-content"
          ></p>
        </h2>

        <div class="flex justify-between items-center">
          <p class="text-white font-semibold">
            {{ new Date(postData.post_created_datetimestamp).toLocaleString() }}
          </p>

          <p class="text-white font-semibold">
            Published by {{ postData.post_Created_by }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      centerDialogVisible55: false,
    };
  },
  props: {
    postData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState("post", ["channelPostDetail"]),
    magittUrl() {
      return this.baseUrlMagitt;
    },
  },
  // mounted() {
  //   console.log(this.postData);
  // }
};
</script>
<style>
.banner-h500px {
  height: 350px;
}
</style>
