<template>
  <a :href="magittUrl + path" download target="_blank">
    <div class="rounded w-64 bg-light cursor-pointer">
      <div class="flex h-24">
        <div class="w-2/5 self-center">
          <img :src="uploadedimage" class="h-16 mx-3" />
        </div>
        <p class="w-3/5 self-center text-lg text-primary">
          Download File
        </p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      uploadedimage: require("@/Core/assets/images/pages/post/PDF_file_icon.svg")
    };
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    }
  }
};
</script>
