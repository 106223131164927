<template>
  <div>
    <div class="poll-view__results">
      <h4>Recent Polls</h4>
      <!-- <p class="mt-6 font-semibold">Have you applied for Loan?</p>
      <div class="result my-4">
        <div class="title">
          Yes
          <span class="percent">25% </span>
          <span class="votes">(30 votes)</span>
        </div>
        <div class="bar"></div>
      </div>
      <div class="result mb-4">
        <div class="title">
          No
          <span class="percent">25% </span>
          <span class="votes">(10 votes)</span>
        </div>
        <div class="bar"></div>
      </div> -->
      <div>
        <div class="poll-view__title" v-html="postData.question"></div>
        <div v-if="!result" class="poll-view__inner">
          <div class="poll-view__help">
            <h5 class="font-sx">Choose your answer:</h5>
          </div>
          <!-- <div class="poll-view__votes">
                      <div v-for="(answer, index) in Answers" :key="index" class="answer">
                          <label class="checkbox">{{ answer }}
                              <input type="radio" v-bind:value="Ids[index]" v-model="poll.vote">
                              <span class="checkmark"></span>
                          </label>
                      </div>
            </div>-->
          <div class="panel">
            <div class="panel-body">
              <ul class="addPost-bottom-user mb-12">
                <li
                  :class="{ checked_color: PrimaryColor }"
                  class="friend-suggestion flex items-center"
                  v-for="(answer, index) of Answers"
                  :key="index"
                  @click.stop="answer = !answer"
                >
                  <label class="cg">
                    <input
                      type="radio"
                      v-model="poll.vote"
                      v-bind:value="Ids[index]"
                      checked="checked"
                    />
                    <!-- <span class="checkmark"></span> -->
                  </label>
                  <div class="w-full">
                    <p class="m-2 h3-class">{{ answer }}</p>
                  </div>

                  <div></div>
                </li>
              </ul>
            </div>
          </div>

          <div class="poll-view__submit">
            <vs-button
              @click="vote"
              color="primary"
              class="h2-class"
              style="border-radius: 6px"
              :disabled="!voteValidation"
            >
              Vote
            </vs-button>
          </div>
          <!-- <div class="poll-view__info" :class="{'success' : success === true, 'error' : success === false}" v-if="success !== null">
                      <div v-if="success === true">Voted</div>
                      <div v-if="success === false">Error</div>
            </div>-->
        </div>
        <div v-if="result" class="poll-view__results">
          <div
            class="result mb-4"
            v-for="(answer, index) in Answers"
            :key="index"
          >
            <div class="title">
              {{ answer }}
              <span class="percent">{{ calculatePercent(votes[index]) }}%</span>
              <span class="votes">({{ votes[index] }} votes)</span>
            </div>
            <div class="bar">
              <div
                :style="{
                  width: calculatePercent(votes[index]) + '%',
                  background: PrimaryColor,
                }"
              ></div>
            </div>
          </div>
        </div>

        <!-- <div v-if="demo" class="poll-view__footer">
                  Made with &hearts; by
                  <a href="https://updivision.com/">updivision.com</a>
          </div>-->
      </div>
      <!-- <vs-button color="primary" class="h2-class" style="border-radius: 6px">
        Vote
      </vs-button> -->
    </div>
    <!-- <div v-if="demo" class="poll-view__footer">
            Made with &hearts; by
            <a href="https://updivision.com/">updivision.com</a>
    </div>-->
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const postsRepository = RepositoryFactory.get("posts");
import PollView from "../../../group/components/PollView.vue";
export default {
  components: {
    PollView,
  },
  name: "poll-view",
  // props: {
  //   Question: "",
  //   Answers: {
  //     type: Array,
  //   },
  //   Ids: {
  //     type: Array,
  //   },
  //   PollId: null,
  //   PostId: null,
  //   AnswersVotes: {
  //     type: Array,
  //   },
  //   votedIds: null,
  // },
  data() {
    return {
      poll: {
        id: 1,
        vote: "",
        // answers: [
        //     {id: 1, answer: "Laravel", votes: 14021},
        //     {id: 2, answer: "Symfony", votes: 3210},
        //     {id: 3, answer: "Phalcon", votes: 3231},
        //     {id: 4, answer: "FuelPhp", votes: 2004},
        //     {id: 5, answer: "Zend Framework", votes: 3132},
        //     {id: 6, answer: "PHPixie", votes: 2131},
        //     {id: 7, answer: "CakePHP", votes: 1222}
        // ],
        answers: [],
      },
      Answers: [],
      Ids: [],
      PostId: null,
      PollId: null,
      votes: [],
      votedIds: null,
      AnswersVotes: [],
      result: false,
      success: null,
      isValid: false,
      token: null,
      PrimaryColor: "bg-primary",
      postData: {},
    };
  },
  mounted() {
    this.PrimaryColor = localStorage.getItem("communityColor");
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);

    // this.CheckVoted();
    this.getlatestpoll();
    this.votes = this.AnswersVotes;
    // axios.get(this.getPollUrl, {
    //     maxContentLength: 2000
    // }).then((response) => {
    //     this.poll = response
    // })
    // .catch((error) => {
    //     console.log(error)
    //     error.request.res.destroy()
    // })
  },
  computed: {
    totalVotes: function () {
      return this.votes.reduce((el, total = 0) => {
        return parseInt(el) + parseInt(total);
      });
    },
    voteValidation() {
      return this.poll.vote == "" ? false : true;
    },
  },
  methods: {
    CheckVoted() {
      if (this.votedIds) {
        if (this.votedIds == this.token.userID) {
          this.success = true;
          this.result = true;
        }
      }
    },
    async vote() {
      let payload = {
        AnswerId: this.poll.vote,
        PostId: this.PostId,
        PollId: this.PollId,
        UserId: this.token.userID,
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await postsRepository.votePoll(payload);
      if (data.ResponseCode == 100) {
        this.votes = data.Data[0].Votes.split(",");
        // this.calculateTotalVotes();
        this.success = true;
        this.result = true;
      }

      // this.validate()
      // demo only ------
      // if (this.demo && this.isValid) {
      //     this.alert(true)
      //     this.calculateTotalVotes();
      // } else {
      // // --------------
      //     if (this.isValid) {
      //         axios.post(this.saveVoteUrl, {
      //             pollId: this.poll.id,
      //             votes: votes
      //         }, {
      //             maxContentLength: 2000
      //         })
      //         .then((response) => {
      //             this.calculateTotalVotes()
      //             this.alert(true)
      //         })
      //         .catch((error) => {
      //             this.alert(false)
      //             error.request.res.destroy()
      //         });
      //     } else {
      //         this.alert(false)
      //     }
      // }
    },
    validate() {
      // const votes = this.poll.answers.filter(answer => answer.voted == true).map(answer => answer.id).length
      // if (votes > 0) {
      //     if (votes > 1) {
      //         if (this.poll.multipleVotes == true) {
      //             this.isValid = true
      //         } else {
      //             this.isValid = false;
      //         }
      //     } else {
      //         this.isValid = true
      //     }
      // } else {
      //     this.isValid = false
      // }
    },
    calculatePercent(votes) {
      return parseInt((votes / this.totalVotes) * 100);
    },
    // calculateTotalVotes() {
    //     this.votes.forEach((vote) => {
    //         this.totalVotes += vote
    //         if (this.poll.vote) {
    //             this.totalVotes += 1
    //         }
    //     })
    // }
    async getlatestpoll() {
      const payload = {
        communityData: {
          communityName: currentCommunityName.communityName,
        },
        Data: {
          limit: 1,
          offset: 0,
          userId: this.token.userID,
        },
      };
      const { data } = await postsRepository.getLatestPollPosts(payload);

      if (data.ResponseCode == 100) {
        this.postData = data.Data[0];
        let dataPoll = this.postData.option;
        for (let i = 0; i < dataPoll.length; i++) {
          this.PostId = this.postData.postId;
          this.Ids.push(dataPoll[i].answerId);
          this.Answers.push(dataPoll[i].answer);
          this.AnswersVotes.push(dataPoll[i].vote);
          this.PollId = this.postData.questionId;
          this.votes = this.AnswersVotes;
        }
        if (this.postData.isVoted == "true") {
          this.result = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.poll-view {
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  width: 100%;
  margin: auto;
  background-color: #fff;
  position: relative;
  transition: all 0.2s ease-in-out;
  border-top: 6px solid #90ee90;
}

label {
  font-weight: 600 !important;
}

.poll-view .checkbox input:checked ~ .checkmark {
  background-color: #90ee90;
}

.poll-view .checkbox input:checked ~ .checkmark:after {
  display: block;
}

.poll-view .checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.poll-view .checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.poll-view__question input {
  box-sizing: border-box;
  background-color: transparent;
  width: 100%;
  color: #333;
  border: 2px solid #4e4c4b8f;
  border-radius: 0;
  padding: 5px 40px 5px 10px;
  line-height: 30px;
  font-size: 30px;
  transition: border 0.2s ease-in-out;
}

.poll-view__question {
  padding-bottom: 30px;
}

.poll-view__inner {
  padding-left: 10px;
}

.poll-view__footer {
  text-align: center;
  color: #333;
  padding-bottom: 5px;
  font-size: 14px;
  opacity: 0.6;
}
.poll-view__answers .answer {
  animation-name: popup;
  animation-duration: 1s;
}

.poll-view__answers .answer,
.poll-view__votes .answer {
  padding-bottom: 5px;
  display: block;
  position: relative;
  background-color: #fff;
}

.poll-view__options {
  padding: 16px 0;
}

.poll-view .checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.poll-view .checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.poll-view .checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.poll-view__submit {
  text-align: left;
  margin-top: -20px;
}

.poll-view {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  margin: auto;
  background-color: #fff;
  position: relative;
  transition: all 0.2s ease-in-out;
  border-top: 6px solid #90ee90;
}

.poll-view__title {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}
.poll-view__results {
  padding: 10px;
}
.poll-view__help {
  font-size: 14px;
  padding-top: 10px;
}

.poll-view__answers .answer,
.poll-view__votes .answer {
  padding-bottom: 5px;
  display: block;
  position: relative;
  background-color: #fff;
}

.poll-view .checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.poll-view .checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.poll-view .checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.poll-view__submit button {
  cursor: pointer;
  color: #fff;
  background-color: #90ee90;
  border: 1px solid #d2f8d2;
  padding: 8px;

  width: 25%;
  transition: background-color 0.2s ease-in-out;
}

.poll-view__submit button:hover {
  opacity: 0.9;
}

.poll-view__results .bar div {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: rgba(var(--vs-primary), 1) !important;
  bottom: 0;
}
.poll-view__results .bar {
  width: 72%;
  height: 25px;
  background-color: #eee;
  position: relative;
}

.poll-view__results .title {
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 600;
}
/* .container {
  margin-bottom: 25px;
  width: 0% !important;
  padding-left: 20px !important;
} */
.font-sx {
  font-size: 14px;
}
.titlee {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
</style>
